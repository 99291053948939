import React from 'react';
import {
    ICruiseSearchFilterParams,
    IMobileSearchResultCruise,
} from '../../../../interfaces/ISearchApi';
import { getPackageDataByMarket } from '../../helper/packageData';
import { MarketContext } from '../../../../context/markets';
import { __ } from '../../../../helpers/TranslationService';
import {
    ImageStyled,
    InfoTransparentIconStyled,
    PackageLabelButton,
    PackageLabelIconStyled,
    PackageLabelText,
    PackageLabelWrapper,
    StyledInfoModal,
    StyledSearchItemContent,
    StyledContentWrapper,
    StyledInfoWrapper,
    StyledOnBoardLabel,
    CruiseImageContainer,
    StyledFavoriteBtn,
} from './styles';
import { SearchResultItemOverlay } from '../SearchResultItemOverlay';
import { SearchResultItemHeader } from './SearchResultItemHeader';
import { SearchResultItemTripInfo } from './SearchResultItemTripInfo';
import { SearchResultItemFeatures } from './SearchResultItemFeatures';
import { formatUrgencyLabels } from '../../../../helpers/cruiseSearchHelper';
import useWindowSize from '../../../../hooks/useViewport';
import { isDesktop } from '../../../../helpers/responsiveHelpers/responsiveHelpers';
import { addToFavorites, removeFromFavorites } from '../../../../actions/favoritesPageActions';
import { SearchResultItemPrices } from './SearchResultItemPrices';
import { TrackComponent } from '../../../../components/TrackComponent';

interface ISearchResultItemV2Props {
    cruise: IMobileSearchResultCruise;
    portalId: string;
    sortString?: string;
    searchPageType?: 'map' | 'destination' | 'ship' | 'mapWithNoUGC';
    isFavorite?: boolean;
    addToFavorites?: typeof addToFavorites;
    removeFromFavorites?: typeof removeFromFavorites;
    hideFavoritesIcon?: boolean;
    discountThreshold: number;
    cabinKindFilterParams?: ICruiseSearchFilterParams['cabinKind'];
}

const iconProps = { width: 18, height: 18 };

const CabinIdsMap = {
    19: 'inside',
    20: 'outside',
    21: 'balcony',
    22: 'suite',
    23: 'shipinship',
};

const eventPackageTrackingData = {
    category: 'search results',
    event: 'click',
    action: 'search result click',
    label: 'dreamlines package',
};

const eventClickTrackingData = { event: 'click', category: 'search result', action: 'click' };

export const SearchResultItemV2 = ({
    cruise,
    portalId,
    sortString,
    searchPageType,
    removeFromFavorites,
    addToFavorites,
    isFavorite,
    discountThreshold,
    cabinKindFilterParams = [],
}: ISearchResultItemV2Props) => {
    const [showModal, setShowModal] = React.useState(false);
    const { markets } = React.useContext(MarketContext);
    const { shipImage, routeImageLarge, routeMapImage } = cruise;
    const [isDesktopAndUp, setIsDesktopAnUp] = React.useState(false);

    const isPackageCruise = cruise.isPackageCruise;
    const packageData = getPackageDataByMarket(portalId, cruise.companyTitle);

    const cabinFilter: string[] = cabinKindFilterParams.map(
        (cabinKindId) => CabinIdsMap[Number(cabinKindId)],
    );

    const cruisePageUrlWithoutHash = cruise.cruisePageUrl.slice(
        0,
        cruise.cruisePageUrl.lastIndexOf('#'),
    );

    const selectedSail = sortString?.includes('discount')
        ? cruise.discountSail
        : cruise.cheapestSail;

    const searchResultItemUrl = cruisePageUrlWithoutHash + '#selectedSail=' + selectedSail.sailNid;

    const headerImage =
        {
            map: routeMapImage,
            mapWithNoUGC: routeMapImage,
            ship: shipImage,
        }[searchPageType] || routeImageLarge;

    const hasRouteImage = Boolean(routeMapImage);

    const title = cruise.routeTitle;

    const companyLogoByMarket = cruise.originalCompanyLogo || cruise.companyAndPackageLogo;

    const onBoardCreditLabel = formatUrgencyLabels(
        cruise.urgencyLabel,
        cruise.obc,
        isPackageCruise,
    ).find((label) => label.type === 'OnBoardCredit');

    const { width } = useWindowSize();

    React.useEffect(() => {
        const isDesktopAndUp = isDesktop(width);
        setIsDesktopAnUp(isDesktopAndUp);
    }, []);

    const TrackableLink = TrackComponent(
        StyledSearchItemContent,
        isPackageCruise ? eventPackageTrackingData : eventClickTrackingData,
    );

    return (
        <>
            {isPackageCruise && (
                <PackageLabelWrapper isCruise1st={markets.isCruise1st}>
                    <PackageLabelText>
                        <PackageLabelIconStyled {...iconProps} />
                        {packageData.title}
                    </PackageLabelText>
                    <PackageLabelButton onClick={() => setShowModal(true)}>
                        <span>{__('read more', 'dreamlines')}</span>
                        <InfoTransparentIconStyled {...iconProps} />
                    </PackageLabelButton>
                </PackageLabelWrapper>
            )}

            <TrackableLink
                isPackage={isPackageCruise}
                href={searchResultItemUrl}
                forceReload={!isDesktopAndUp}
                target={isDesktopAndUp ? '_self' : '_blank'}
            >
                <SearchResultItemHeader
                    className="hideElementMobile"
                    logo={companyLogoByMarket}
                    title={title}
                />

                <StyledContentWrapper>
                    <CruiseImageContainer isPackage={isPackageCruise}>
                        <ImageStyled
                            src={headerImage || shipImage}
                            alt={cruise.companyTitle}
                            className={hasRouteImage ? 'routeImage' : ''}
                        />
                        <StyledFavoriteBtn
                            dropShadow="none"
                            wrapperStyle={{ padding: 0 }}
                            cruiseNid={cruise.cruiseNid}
                            isFavorite={isFavorite}
                            addToFavorites={addToFavorites}
                            removeFromFavorites={removeFromFavorites}
                        />
                    </CruiseImageContainer>

                    <SearchResultItemHeader
                        className="hideElementDesktopAndUp"
                        logo={companyLogoByMarket}
                        title={title}
                    />

                    <StyledInfoWrapper>
                        <SearchResultItemTripInfo
                            shipTitle={cruise.shipTitle}
                            nights={cruise.nights}
                            dateRange={cruise.dateRange}
                            wayPointCities={cruise.wayPointCities}
                            sails={cruise.sails}
                            earliestDeparture={cruise.earliestDeparture}
                            latestArrival={cruise.latestArrival}
                        />
                        <SearchResultItemFeatures icons={cruise.icons} />

                        {onBoardCreditLabel && (
                            <StyledOnBoardLabel>{onBoardCreditLabel.value}</StyledOnBoardLabel>
                        )}

                        {Boolean(cruise.topSail.rates) && (
                            <SearchResultItemPrices
                                topSail={cruise.topSail}
                                operator={cruise.companyCode}
                                discountThreshold={discountThreshold}
                                cabinFilter={cabinFilter}
                            />
                        )}
                    </StyledInfoWrapper>
                </StyledContentWrapper>
            </TrackableLink>
            {showModal && (
                <StyledInfoModal isOpen onClose={() => setShowModal(false)}>
                    <SearchResultItemOverlay
                        cruise={cruise}
                        selectedSail={selectedSail}
                        packagesUrl={packageData.url}
                    />
                </StyledInfoModal>
            )}
        </>
    );
};
